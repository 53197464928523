import React, { useEffect, useState } from 'react';
import { AppBar, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styles from './Header.module.scss';
import { styled } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';

function Header() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:480px)');  
  const handleNavigate = (path) => {
    navigate(path);
  };
  return (
    <StyledAppBar>
      <FlexBox>
        <LogoBox>
          <img src="/logo-white.png" alt="Logo" width="40" height="40" />
        </LogoBox>
        <MenuBox>
            <StyledButton onClick={() => handleNavigate('/')}>Accueil</StyledButton>
            <StyledButton onClick={() => handleNavigate('/portfolio')}>Portfolio</StyledButton>
            <StyledButton onClick={() => handleNavigate('/contact')}>Contact</StyledButton>
        </MenuBox>
      </FlexBox>
    </StyledAppBar>
  );
}


const StyledAppBar = styled(AppBar)({
    // boxShadow:'0px 2px 4px -1px rgba(0,0,0,0.1),0px 4px 5px 0px rgba(0,0,0,0.05),0px 1px 10px 0px rgba(0,0,0,0.10)',
    boxShadow:'none',
    backgroundColor: 'transparent',
    height: '80px',
    top: 0,
    left: 0,
    zIndex: 1000,
    position: 'fixed',
});

const FlexBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '100%',

});

const LogoBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  width: '33%',
  paddingLeft: '20px',
  height: '100%',
  '@media (max-width: 480px)': {
    width: '20%',
  },
});


const MenuBox = styled(Box)({
  backgroundColor: 'transparent',
  height: "100%",
  width: '33%',
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  paddingLeft: '10px',
  '@media (max-width: 480px)': {
    width: '80%',
    justifyContent: 'space-between',
    marginRight:'25px',
  },

});

const StyledButton = styled(Button)({
  textTransform: 'none',
  fontSize: '15px',
  color: 'white',
  fontFamily: 'AkiraExpanded',

});
export default Header;
