import React, { useState, useEffect, useRef } from 'react';

import styles from './Portfolio.module.scss';

function Portfolio() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    
  return (
    <div className={styles.portfolio}>
        <div className={styles.portfolio__divPortfolio}>
            <img src="/assets/Portfolio.png" className={styles.portfolio__imgPortfolio}/>
            
            <img src="/assets/img/site.png" className={styles.portfolio__imgSitePortfolio}/>

            <div className={styles.gridPortfolio}>
            <a href="https://dovana.dorianbidault.fr" className={styles.gridItem} style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">

                <div className={styles.gridImg}>
                    <img src='/assets/img/portfolio-dovana.png'/>
                </div>
                <div className={styles.gridInfos}>
                    <p className={styles.webTitle}>Dovana</p>
                    <p className={styles.webTech}>React & Symfony <span>(3ème Année BUT)</span></p>
                    <p className={styles.webDesc}>Ce projet associe service et IA pour aider les utilisateurs à dénicher des idées cadeaux.</p>
                </div>
            </a>
            <a href="https://www.exostia.com" className={styles.gridItem} style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">

                <div className={styles.gridImg}>
                    <img src='/assets/img/portfolio-exostia.png'/>
                </div>
                <div className={styles.gridInfos}>
                    <p className={styles.webTitle}>Exostia</p>
                    <p className={styles.webTech}>CMS Azuriom <span>(Projet Perso)</span></p>
                    <p className={styles.webDesc}>Ce projet permet aux joueurs d'Exostia de voter et acheter pour des avantages en jeu.</p>
                </div>
            </a>
            <a href="https://nopixel.dorianbidault.fr" className={styles.gridItem} style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">

                <div className={styles.gridImg}>
                    <img src='/assets/img/portfolio-nopixel.png'/>
                </div>
                <div className={styles.gridInfos}>
                    <p className={styles.webTitle}>NoPIXEL</p>
                    <p className={styles.webTech}>CMS WordPress <span>(3ème Année BUT)</span></p>
                    <p className={styles.webDesc}>Pour ce projet, nous devions crée un thème WordPress</p>
                </div>
            </a>
            <a href="/emeraldmaze" className={styles.gridItem} style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">

                <div className={styles.gridImg}>
                    <img src='/assets/img/portfolio-maze.png'/>
                </div>
                <div className={styles.gridInfos}>
                    <p className={styles.webTitle}>Emerald Maze</p>
                    <p className={styles.webTech}>Three.js <span>(2ème Année BUT)</span></p>
                    <p className={styles.webDesc}>Nous devions crée un jeu en utilisant la librairie de 3D</p>
                </div>
            </a>
            <a href="/hangart" className={styles.gridItem} style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">

                <div className={styles.gridImg}>
                    <img src='/assets/img/portfolio-hangart.png'/>
                </div>
                <div className={styles.gridInfos}>
                    <p className={styles.webTitle}>Hangart</p>
                    <p className={styles.webTech}>HTML/CSS/JS <span>(2ème Année BUT)</span></p>
                    <p className={styles.webDesc}>Nous devions crée un site pour un événement</p>
                </div>
            </a>
            <a href="/eshop" className={styles.gridItem} style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">

                <div className={styles.gridImg}>
                    <img src='/assets/img/portfolio-eshop.png'/>
                </div>
                <div className={styles.gridInfos}>
                    <p className={styles.webTitle}>BUT ESHOP</p>
                    <p className={styles.webTech}>HTML/SASS <span>(3ème Année BUT)</span></p>
                    <p className={styles.webDesc}>Petit projet d'intégration de maquette en SASS</p>
                </div>
            </a>
        </div>
        <img src="/assets/img/autres-jaune.png" className={styles.portfolio__imgSubPortfolio}/>

        <div className={styles.gridPortfolio}>
            <div className={styles.gridItem}>
                <div className={styles.gridImg}>
                    <img src='/assets/img/portfolio-box3D.png'/>
                </div>
                <div className={styles.gridInfos}>
                    <p className={styles.webTitle}>Box 3D</p>
                    <p className={styles.webTech}>Blender <span>(Projet Perso)</span></p>
                    <p className={styles.webDesc}>Pour ce projet, je voulais réaliser une sorte de moteur/batterie un peu futuriste en 3D</p>
                </div>
            </div>
            <div className={styles.gridItem}>
                <div className={styles.gridImg}>
                    <img src='/assets/img/portfolio-corbeau.jpg'/>
                </div>
                <div className={styles.gridInfos}>
                    <p className={styles.webTitle}>Cover Corbeau</p>
                    <p className={styles.webTech}>Photoshop <span>(Projet Perso)</span></p>
                    <p className={styles.webDesc}>Pour ce projet, je devais réaliser une cover représentant quelque chose de spéciale et sombre</p>
                </div>
            </div>
            <div className={styles.gridItem}>
                <div className={styles.gridImg}>
                    <img src='/assets/img/portfolio-pil.png'/>
                </div>
                <div className={styles.gridInfos}>
                    <p className={styles.webTitle}>Cover Pilule</p>
                    <p className={styles.webTech}>Photoshop <span>(Projet Perso)</span></p>
                    <p className={styles.webDesc}>Ce projet est une version alternative et plus expérimental de la cover du corbeau mais </p>
                </div>
            </div>
            <div className={styles.gridItemDessin}>
                <div className={styles.gridImg}>
                    <img src='/assets/img/portfolio-dessin2.png'/>
                </div>
                <div className={styles.gridInfos}>
                    <p className={styles.webTitle}>Photographe</p>
                    <p className={styles.webTech}>Dessin <span>(Projet Perso)</span></p>
                    <p className={styles.webDesc}>Inspiré d'une photographie, ce dessin représente une personne prenant une photographie</p>
                </div>
            </div>
            <div className={styles.gridItemDessin}>
                <div className={styles.gridImg}>
                    <img src='/assets/img/portfolio-dessin1.png'/>
                </div>
                <div className={styles.gridInfos}>
                    <p className={styles.webTitle}>Portrait No.1</p>
                    <p className={styles.webTech}>Dessin <span>(Projet Perso)</span></p>
                    <p className={styles.webDesc}>Inspiré d'une photographie</p>
                </div>
            </div>
            <div className={styles.gridItemDessin}>
                <div className={styles.gridImg}>
                    <img src='/assets/img/portfolio-dessin3.png'/>
                </div>
                <div className={styles.gridInfos}>
                    <p className={styles.webTitle}>Portrait No.2</p>
                    <p className={styles.webTech}>Dessin <span>(Projet Perso)</span></p>
                    <p className={styles.webDesc}>Inspiré d'une photographie</p>
                </div>
            </div>
         
        </div>
      </div>
    </div>
  );
}

export default Portfolio;
