import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/system';
import { Container } from '@mui/material';
import styles from './Home.module.scss';
import { Link } from 'react-router-dom';

function Home() {
  const refs = useRef({});
  const [visibility, setVisibility] = useState({});
  const cvPhotoRef = useRef(null); 
  const zoomTextRef = useRef(null); 

  const zoomCVRef = useRef(null); 
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          setVisibility(prevVisibility => ({
            ...prevVisibility,
            [entry.target.getAttribute('data-index')]: entry.isIntersecting,
          }));
        });
      },
      {
        rootMargin: '0px',
        threshold: 0.3,
      }
    );

    Object.values(refs.current).forEach(ref => {
      if (ref) observer.observe(ref);
    });

    return () => {
      Object.values(refs.current).forEach(ref => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  const setRef = (index) => (el) => {
    refs.current[index] = el;
  };
  useEffect(() => {
    const img = cvPhotoRef.current; 
    const zoomCV = zoomCVRef.current;
    const zoomText = zoomTextRef.current;

    if (img && zoomCV) {
      const handleMouseMove = (e) => {
        const { left, top, width, height } = img.getBoundingClientRect();
        const x = (e.clientX - left) / width;
        const y = (e.clientY - top) / height;
  
        const zoomFactor = 0.3;
        zoomCV.style.backgroundImage = `url(${img.src})`;
        zoomCV.style.backgroundRepeat = 'no-repeat';
        zoomCV.style.backgroundSize = `${img.naturalWidth * zoomFactor}px ${img.naturalHeight * zoomFactor}px`;  
        const bgPosX = (x * img.naturalWidth * zoomFactor) - (width / 1);
        const bgPosY = (y * img.naturalHeight * zoomFactor) - (height / 1);
        zoomCV.style.backgroundPosition = `-${bgPosX+80}px -${bgPosY+210}px`;
        zoomText.style.display = 'none';

      };

      img.addEventListener('mousemove', handleMouseMove);
  
      return () => {
        img.removeEventListener('mousemove', handleMouseMove);
      };
    }
  }, []);
  
  
  

  return (
    <div className={styles.home}>
      <div
        className={styles.home__divImg}
        ref={setRef(1)}
        data-index="1"
        style={{
          opacity: visibility[1] ? 1 : 0, 
          transition: 'opacity 0.5s ease-in-out',
        }}
      >
        <p className={styles.home__firstDesign}>░░ ✦✦✧✧</p>
        <img src="/assets/Portfolio.png" className={styles.home__img}/>
        <p className={styles.home__secondDesign}>✦✦✧✧ ░░</p>
      </div>
      <div
        className={styles.home__divPresentation}
        ref={setRef(2)} 
        data-index="2"
        style={{
          opacity: visibility[2] ? 1 : 0,
          transition: 'opacity 0.5s ease-in-out',
        }}
      >
        <div className={styles.home__divPresPhoto}>
            <p className={styles.home__firstDesignPres}>░░ ✦✦✧✧</p>
            <img src="/assets/img/photo.png" className={styles.home__img}/>
            <p className={styles.home__secondDesignPres}>✦✦✧✧ ░░</p>
        </div>
        <div className={styles.home__infoPhoto}>
            <p className={styles.titlePhoto}><span>/// </span>Présentation</p>
            <div></div>
            <p className={styles.descPhoto}>
            Passionné d'informatique, je m'adapte à divers défis avec détermination et esprit d'équipe. 
            Mon désir constant d'apprendre font de moi un collaborateur engagé. 
            Hors du cadre professionnel, mes passions incluent la musique allant de l'écriture à la production, ainsi que le mixage 
            et le mastering, ce qui révèle mon attention au détail et mon écoute critique. 
            <br></br>   <br></br>
            Ma passion pour le sport transparaît également, à travers ma participation à des compétitions de 
            force athlétique et mon intérêt pour le MMA, où je cherche à repousser mes limites physiques et mentales. 
            <br></br>   <br></br>
            Ces passions, combinées à mon parcours en informatique, illustrent mon aspiration à l'excellence, 
            à la fois professionnelle et personnelle, soulignant l'importance de la persévérance, de l'esprit d'équipe et 
            de l'amélioration continue dans la réalisation des objectifs.
            </p>
        </div>
        
      </div>
      <div
        className={styles.home__divCV}
        ref={setRef(3)} 
        data-index="3"
        style={{
          opacity: visibility[3] ? 1 : 0,
          transition: 'opacity 0.5s ease-in-out',
        }}
      >
        
        <div ref={zoomCVRef} className={styles.home__zoomCV}>
            <p ref={zoomTextRef} className={styles.cursorText}>Placer votre curseur sur le CV pour zoomer</p>
        </div>
        <div className={styles.home__divCVPhoto} >
            <p className={styles.home__firstDesignPres}>░░ ✦✦✧✧</p>
            <img src="/assets/img/CV.png" className={styles.home__imgZoom} ref={cvPhotoRef}/>
            <p className={styles.home__secondDesignPres}>✦✦✧✧ ░░</p>
        </div>
        
      </div>
      <div
        className={styles.home__divPortfolio}
        ref={setRef(4)}
        data-index="4"
        style={{
          opacity: visibility[4] ? 1 : 0, 
          transition: 'opacity 0.5s ease-in-out',
        }}
      >
        <div className={styles.home__divTextPortfolio}>
            <p className={styles.home__firstPortDesign}>░░ ✦✦✧✧</p>
            <img src="/assets/Portfolio.png" className={styles.home__imgPortfolio}/>
            <p className={styles.home__secondPortDesign}>✦✦✧✧ ░░</p>
        </div>
       
        
        <img src="/assets/img/site.png" className={styles.home__imgSitePortfolio}/>

        <div className={styles.gridPortfolio}>
            <a href="https://dovana.dorianbidault.fr" className={styles.gridItem} style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">

                <div className={styles.gridImg}>
                    <img src='/assets/img/portfolio-dovana.png'/>
                </div>
                <div className={styles.gridInfos}>
                    <p className={styles.webTitle}>Dovana</p>
                    <p className={styles.webTech}>React & Symfony <span>(3ème Année BUT)</span></p>
                    <p className={styles.webDesc}>Ce projet associe service et IA pour aider les utilisateurs à dénicher des idées cadeaux.</p>
                </div>
            </a>
            <a href="https://www.exostia.com" className={styles.gridItem} style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">

                <div className={styles.gridImg}>
                    <img src='/assets/img/portfolio-exostia.png'/>
                </div>
                <div className={styles.gridInfos}>
                    <p className={styles.webTitle}>Exostia</p>
                    <p className={styles.webTech}>CMS Azuriom <span>(Projet Perso)</span></p>
                    <p className={styles.webDesc}>Ce projet permet aux joueurs d'Exostia de voter et acheter pour des avantages en jeu.</p>
                </div>
            </a>
            <a href="https://nopixel.dorianbidault.fr" className={styles.gridItem} style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">

                <div className={styles.gridImg}>
                    <img src='/assets/img/portfolio-nopixel.png'/>
                </div>
                <div className={styles.gridInfos}>
                    <p className={styles.webTitle}>NoPIXEL</p>
                    <p className={styles.webTech}>CMS WordPress <span>(3ème Année BUT)</span></p>
                    <p className={styles.webDesc}>Pour ce projet, nous devions crée un thème WordPress</p>
                </div>
            </a>
            <a href="/emeraldmaze" className={styles.gridItem} style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">

                <div className={styles.gridImg}>
                    <img src='/assets/img/portfolio-maze.png'/>
                </div>
                <div className={styles.gridInfos}>
                    <p className={styles.webTitle}>Emerald Maze</p>
                    <p className={styles.webTech}>Three.js <span>(2ème Année BUT)</span></p>
                    <p className={styles.webDesc}>Nous devions crée un jeu en utilisant la librairie de 3D</p>
                </div>
            </a>
            <a href="/hangart" className={styles.gridItem} style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">

                <div className={styles.gridImg}>
                    <img src='/assets/img/portfolio-hangart.png'/>
                </div>
                <div className={styles.gridInfos}>
                    <p className={styles.webTitle}>Hangart</p>
                    <p className={styles.webTech}>HTML/CSS/JS <span>(2ème Année BUT)</span></p>
                    <p className={styles.webDesc}>Nous devions crée un site pour un événement</p>
                </div>
            </a>
            <a href="/eshop" className={styles.gridItem} style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">

                <div className={styles.gridImg}>
                    <img src='/assets/img/portfolio-eshop.png'/>
                </div>
                <div className={styles.gridInfos}>
                    <p className={styles.webTitle}>BUT ESHOP</p>
                    <p className={styles.webTech}>HTML/SASS <span>(3ème Année BUT)</span></p>
                    <p className={styles.webDesc}>Petit projet d'intégration de maquette en SASS</p>
                </div>
            </a>
        </div>
        <img src="/assets/img/autres-jaune.png" className={styles.home__imgSubPortfolio}/>

        <div className={styles.gridPortfolio}>
          <div className={styles.gridItem}>
                <div className={styles.gridImg}>
                    <img src='/assets/img/portfolio-box3D.png'/>
                </div>
                <div className={styles.gridInfos}>
                    <p className={styles.webTitle}>Box 3D</p>
                    <p className={styles.webTech}>Blender <span>(Projet Perso)</span></p>
                    <p className={styles.webDesc}>Pour ce projet, je voulais réaliser une sorte de moteur/batterie un peu futuriste en 3D</p>
                </div>
            </div>
            <div className={styles.gridItem}>
                <div className={styles.gridImg}>
                    <img src='/assets/img/portfolio-corbeau.jpg'/>
                </div>
                <div className={styles.gridInfos}>
                    <p className={styles.webTitle}>Cover Corbeau</p>
                    <p className={styles.webTech}>Photoshop <span>(Projet Perso)</span></p>
                    <p className={styles.webDesc}>Pour ce projet, je devais réaliser une cover représentant quelque chose de spéciale et sombre</p>
                </div>
            </div>
            <div className={styles.gridItem}>
                <div className={styles.gridImg}>
                    <img src='/assets/img/portfolio-pil.png'/>
                </div>
                <div className={styles.gridInfos}>
                    <p className={styles.webTitle}>Cover Pilule</p>
                    <p className={styles.webTech}>Photoshop <span>(Projet Perso)</span></p>
                    <p className={styles.webDesc}>Ce projet est une version alternative et plus expérimental de la cover du corbeau mais </p>
                </div>
            </div>
        </div>
      </div>
      <div className={styles.divBtnPortfolio}>
            <Link to="/portfolio" style={{ textDecoration: 'none' }}>
                <button className={styles.btnPortfolio}>Voir plus</button>
            </Link>
      </div>
    </div>
  );
}

export default Home;
