import React, { useState, useEffect, useRef } from 'react';

import styles from './Contact.module.scss';

function Contact() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    
  return (
    <div className={styles.contact}>
        <div className={styles.contact__divContact}>
            <img src="/assets/img/contact.png" className={styles.contact__imgContact}/>
            <div className={styles.divEmail}>
                <img src="/assets/img/email.png" className={styles.contact__imgEmail}/>
                <p className={styles.webTech}>dorian.bidault11@gmail.com</p>

            </div>
            <div className={styles.divEmail}>
                <img src="/assets/img/number.png" className={styles.contact__imgNumber}/>
                <p className={styles.webTech}>07 50 01 07 85</p>

            </div>
            <div className={styles.divEmail}>
                <img src="/assets/img/network.png" className={styles.contact__imgNetwork}/>
                <p className={styles.webTech}>...</p>

            </div>

         
        
        </div>
    </div>
  );
}

export default Contact;
